/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import AutopsyMain from "assets/img/AutopsyMain.svg";

// reactstrap components


function About() {


  return (
    <><title>Avatar</title>
      <div className="sm:flex items-center max-w-screen-xl">
        <div className="sm:w-1/2 p-10">
          <div className="image object-center text-center">
            <img src={AutopsyMain} alt=""></img>
          </div>
        </div>
        <div className="sm:w-1/2 p-5">
          <div className="text">
            <h2 className="my-4 font-bold text-3xl  sm:text-4xl "><span className="text-red-600">Avatar</span>
            </h2>
            <p className="text-gray-700">
              NACF-MARS simplifies MHE assest PM(Preventive Maintenance) with <span className="text-red-600">Avatar Tool</span>!
            </p>
            <p className="text-gray-700">
              Avatar is a automated Verification and validation tool that empowers Fullfillment Center's CSX and minimizes operational
              impact. Autopsy reduces the time required per PM, enables more efficient PMs, and reduces the risk of downtime and disruption.
              Avatar automates the PMs, provides visual validations for the devices connected to the each assests, and provides the
              results in near-real time.These benefits will drive a $X.XMM savings across NACF network
            </p>
            <p className="text-gray-700">
              Avatar provides Validation Mode that automates Verification and Validation (V&V) during PMs with help of added sensors, cameras and integrated programming interface.
              Furthermore, it has capability to generate reports and provide assistance to CSX to identify damaged components of the assets. This drives consistancy, accuracy and efficiency during PM.
            </p>
            <p className="text-gray-700">
              Avatar can be the ally during a High Severity event to conduct troubleshooting faster.
            </p>
          </div>
        </div>
      </div></>
  );
}
export default About;