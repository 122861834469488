import Dashboard from "views/Dashboard.js";
import VFD from "views/VfdPage.js";
import Sheild from "views/SevHelper.js";
import About from "views/About.js";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <Dashboard />,
    layout: "/admin",
  },
  {
    path: "/VFDPage",
    name: "VFD",
    icon: "tim-icons icon-atom",
    component: <VFD />,
    layout: "/admin",
  },

  {
    path: "/Sheild",
    name: "Shield",
    icon: "tim-icons icon-bell-55",
    component: <Sheild />,
    layout: "/admin",
  },
  {
    path: "/About",
    name: "About",
    icon: "tim-icons icon-single-02",
    component: <About />,
    layout: "/admin",
  },
];
export default routes;
