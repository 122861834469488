import { useEffect } from "react";
import _ from "lodash";
import "@cloudscape-design/global-styles/index.css"
import { useDispatch } from "react-redux";
import * as React from "react";
import ChatBubble from "@cloudscape-design/chat-components/chat-bubble";
import Avatar from "@cloudscape-design/chat-components/avatar";
import ButtonGroup from "@cloudscape-design/components/button-group";
import StatusIndicator from "@cloudscape-design/components/status-indicator";
import PromptInput from "@cloudscape-design/components/prompt-input"
import { NonCancelableCustomEvent, SpaceBetween } from "@cloudscape-design/components";
//import { BaseKeyDetail } from "@cloudscape-design/components/internal/events";
import { useState } from 'react';


async function SubmissionHandler(value) {
    try {
        const response = await window.api.getChatResponse(value);
        // Assuming the response is an object, extract the relevant field, e.g., `data`
        return response?.data ?? "Failed to get answer";
    } catch (error) {
        console.error('Error fetching chat response:', error);
        return "An error occurred while processing your request.";
    }
}


const Sheild = () => {

    return (
        <>
            <ChatBubble
                ariaLabel="John Doe at 5:29:02pm"
                showLoadingBar
                type="incoming"
                avatar={
                    <Avatar
                        ariaLabel="John Doe"
                        iconName="gen-ai"
                        initials='dsshah'
                    />
                }
            >
                <div style={{ display: "flex", alignItems: "center", width: "350px", height: "50px", border: "none" }}>
                    <PromptInput
                        onChange=""
                        value=""
                        ariaLabel="Prompt input"
                        autoFocus
                        placeholder="How can NACF-SysEng help?"
                        onKeyDown=""
                        className="no-border" // Assuming this class removes borders
                    />
                </div>
            </ChatBubble>
            <div style={{ marginTop: "50px" }} />

            <SpaceBetween direction="vertical" size="xxl">
                <div className="no-border" style={{ width: "500px", height: "200px" }}>
                    <ChatBubble
                        ariaLabel="Generative AI assistant at 6:35:10pm"
                        type="incoming"
                        actions={
                            <ButtonGroup
                                ariaLabel="Chat bubble actions"
                                variant="icon"
                                items={[
                                    {
                                        type: "group",
                                        text: "Feedback",
                                        items: [
                                            { type: "icon-button", id: "helpful", iconName: "thumbs-up", text: "Helpful" },
                                            { type: "icon-button", id: "not-helpful", iconName: "thumbs-down", text: "Not helpful" }
                                        ]
                                    },
                                    {
                                        type: "icon-button",
                                        id: "copy",
                                        iconName: "copy",
                                        text: "Copy",
                                        popoverFeedback: <StatusIndicator type="success">Message copied</StatusIndicator>
                                    }
                                ]}
                            />
                        }
                        avatar={
                            <Avatar
                                color="gen-ai"
                                loading
                                ariaLabel="Generative AI assistant"
                                tooltipText="Generative AI assistant"
                            />
                        }
                    >
                    </ChatBubble>
                </div>
                {/* Add a button for toggling ticket creation */}
            </SpaceBetween>
        </>
    );
};

export default Sheild;
