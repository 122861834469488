import { createContext } from "react";

export const backgroundColors = {
  primary: "primary",
  blue: "blue",
  green: "green",
  pink: "pink",
  red: "red",
  yellow: "yellow",
  orange: "orange",
  purple: "purple",
  grey: "grey",
  black: "black",
  white: "white"
};

export const BackgroundColorContext = createContext({
  color: backgroundColors.primary,
  changeColor: (color) => { },
});
